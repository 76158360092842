import { DgComponent } from '@/enums/dgComponent';
import { ReadonlyDGDataHub } from '@/models/DGDataHub.readonly.model';
import { PageDGDataHub } from '@/models/PageDgDataHub.model';
import DDC from './constants/ddc';
import { DdcApi, DdcConfig, DdcEvent } from './ddcApi';
import { getDeployEnvFromLoaderScriptUrl } from './utils/getDeployEnv';
import { getLexusBuildsIFrame, buildYourLexusUrl, buildYourLexusIframeId } from './utils/getLexusBuildsIFrame';

const setIFrameSrcWithDGData = (dgData: string) => {
    const iframe = getLexusBuildsIFrame();
    if (!iframe) {
        throw new Error('Could not find lexus builds iframe');
    }
    const src = `https:${buildYourLexusUrl}/#!/dealer/${ReadonlyDGDataHub.dealerCd}/zip/${ReadonlyDGDataHub.zipCode}/series/?parent=${window.location.origin}/&`;
    iframe.src = src + dgData;
};

class DataHubBuilder {
    dataHub: Partial<PageDGDataHub>;

    constructor(initialDataHub: Partial<PageDGDataHub>) {
        this.dataHub = initialDataHub;
    }

    build() {
        return this.dataHub as PageDGDataHub;
    }

    async setupBuildsPage(ddcApi: DdcApi, dealerEventInfo: DdcEvent): Promise<void> {
        this.dataHub.setIFrameSrcWithDGData = setIFrameSrcWithDGData;
        this.dataHub.zipCode = dealerEventInfo.payload.dealershipPostalCode;

        if (!getLexusBuildsIFrame()) {
            const containerEl = document.createElement('iframe');
            containerEl.id = buildYourLexusIframeId;
            containerEl.setAttribute('style', 'width: 100%; height: 6200px');
            await ddcApi.insertElementToContent(containerEl);
        }
    }

    setPageRoutesOnDataHub(config: DdcConfig) {
        if (this.dataHub.BRAND === 'lexus') {
            this.dataHub.pageRoutes = {};
            this.dataHub.pageRoutes.buildRoute = config[DDC.BUILD_PAGE.LOOKUP_KEY] || DDC.BUILD_PAGE.DEFAULT_PATH;
        }
    }

    setDealerCodeOnDataHub(dealerEventInfo: DdcEvent) {
        this.dataHub.dealerCd =
            dealerEventInfo.payload.dealershipCodes['toyota_smartpath'] ||
            dealerEventInfo.payload.dealershipCodes['lexus'];
    }

    setBrandOnDataHub(pageEventInfo: DdcEvent) {
        this.dataHub.BRAND = pageEventInfo.payload.franchises[0];
    }

    setMenuIconColor(pageEventInfo: DdcEvent, config: DdcConfig) {
        this.dataHub.invertNavMenuIcons = config.invertIconDesktop ?? false;
        if (pageEventInfo.payload.layoutType === 'mobile') {
            this.dataHub.invertNavMenuIcons = config.invertIconMobile ?? false;
        }
    }
    setOnboardingModal(config: DdcConfig) {
        this.dataHub.autoOpenOnboardingModal = !config.hideOnboardingModal;
    }

    setShowContinuePurchaseText(config: DdcConfig) {
        this.dataHub.showContinuePurchaseText = config.showContinuePurchaseText;
    }
}

export const buildDGDataHub = async (): Promise<PageDGDataHub> => {
    const dataHubBuilder = new DataHubBuilder({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        DEPLOY_ENV: getDeployEnvFromLoaderScriptUrl(),
        components: {
            [DgComponent.navMenu]: true,
            [DgComponent.progressTracker]: true,
            [DgComponent.inlineSaveHeart]: false,
            [DgComponent.toastNotification]: true,
            [DgComponent.shoppingCart]: true,
            [DgComponent.topNavSaveHeart]: true,
        },
        appVersion: 'latest',
        sidebarSettings: {
            display: true,
        },
    });

    const ddcApi = new DdcApi();
    const dealerEventInfo = await ddcApi.getEventInfo(DDC.DEALER_EVENT_NAME);
    const pageEventInfo = await ddcApi.getEventInfo(DDC.PAGE_EVENT_NAME);
    const ddcConfig = await ddcApi.getConfig();

    dataHubBuilder.setBrandOnDataHub(pageEventInfo);
    dataHubBuilder.setPageRoutesOnDataHub(ddcConfig);
    dataHubBuilder.setDealerCodeOnDataHub(dealerEventInfo);
    dataHubBuilder.setMenuIconColor(pageEventInfo, ddcConfig);
    dataHubBuilder.setOnboardingModal(ddcConfig);
    dataHubBuilder.setShowContinuePurchaseText(ddcConfig);

    if (
        pageEventInfo.payload.pageName === DDC.BUILD_PAGE.ALIAS ||
        pageEventInfo.payload.pageName === 'BUILD_YOUR_LEXUS'
    ) {
        await dataHubBuilder.setupBuildsPage(ddcApi, dealerEventInfo);
    }

    return dataHubBuilder.build();
};

export const initializeDGDataHub = async (): Promise<void> => {
    (window.DGDataHub as PageDGDataHub) = await buildDGDataHub();
};
