import { Environments } from '@/enums/environments';

const LOADER_SCRIPT_NAME = 'dg-loader-app';

export const getDeployEnvFromLoaderScriptUrl = (): Environments => {
    const script = (document.currentScript ||
        document.querySelector(`[src*=${LOADER_SCRIPT_NAME}]`)) as HTMLScriptElement;
    const url = script?.src;
    if (url?.includes('.demo')) {
        return Environments.demo;
    } else if (url?.includes('.stage') || url?.includes('localhost')) {
        return Environments.stage;
    } else if (url?.includes('.test')) {
        return Environments.test;
    } else if (url?.includes('.dev')) {
        return Environments.dev;
    } else {
        return Environments.prod;
    }
};
