/* eslint-disable @typescript-eslint/naming-convention */
const ddcConstants = {
    INTEGRATION_NAME: 'toyota-smartpath',
    DEALER_EVENT_NAME: 'dealership-info-v1',
    PAGE_EVENT_NAME: 'page-load-v1',
    SAVES_PAGE: {
        ALIAS: 'SMARTPATH_DIGITAL_GARAGE_SAVES',
        DEFAULT_PATH: '/digital-garage/saves.htm',
        LOOKUP_KEY: 'savesUrl',
    },
    DEALS_PAGE: {
        ALIAS: 'SMARTPATH_DIGITAL_GARAGE_DEALS',
        DEFAULT_PATH: '/digital-garage/deals.htm',
        LOOKUP_KEY: 'dealsUrl',
    },
    REDIRECT_PAGE: {
        ALIAS: 'SMARTPATH_DIGITAL_GARAGE_REDIRECT',
        DEFAULT_PATH: '/digital-garage/redirect.htm',
        LOOKUP_KEY: 'redirectUrl',
    },
    BUILD_PAGE: {
        ALIAS: 'SMARTPATH_DIGITAL_GARAGE_BUILD',
        DEFAULT_PATH: '/digital-garage/build.htm',
        LOOKUP_KEY: 'buildsUrl',
    },
};

Object.freeze(ddcConstants);

export default ddcConstants;
