import { getDgUiScriptUrl } from '@/utilities/scriptUrls';
import { loadScript } from '@/utilities/loadScript';
import LoaderLogger from './loaderLogger';
import { AppScript } from '@/enums/appScripts';

const LATEST = 'latest';

const getScriptSource = (appName: string, version: string) => {
    const url = {
        [AppScript.dgUI]: getDgUiScriptUrl(version),
    }[appName];

    return url || null;
};
const loadAppScript = async (appName: string, version: string) => {
    const url = getScriptSource(appName, version);
    if (url === null) {
        LoaderLogger.warn('Could not get script url', { appName, version });
        return;
    }

    LoaderLogger.debug('Loading script', { appName, version, url });
    await loadScript(url);
};

export function loadRequiredScripts(): Promise<void[]> {
    const versionsToLoad = {
        [AppScript.dgUI]: LATEST,
    };
    LoaderLogger.info('Loading DG scripts', versionsToLoad);
    return Promise.all(Object.entries(versionsToLoad).map(([appName, version]) => loadAppScript(appName, version)));
}
