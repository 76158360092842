/*
Link to DDCAPI
https://dealerdotcom.github.io/web-integration-api-docs/#overview 
*/
import DDC from './constants/ddc';

export type DdcEvent = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: Record<string, any>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DdcConfig = Record<string, any>;

export class DdcApi {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiPromise: Promise<any>;

    constructor() {
        if (!window.DDC) {
            throw new Error('DDC not available on the window');
        }
        this.apiPromise = window.DDC.APILoader.create(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (document.currentScript as any) ||
                /* for use in prod */ /* for use when local testing */ DDC.INTEGRATION_NAME
        );
    }

    getEventInfo(eventName: string): Promise<DdcEvent> {
        return new Promise((resolve) => {
            this.apiPromise.then((api) => api.subscribe(eventName, (ev: DdcEvent) => resolve(ev)));
        });
    }

    getConfig(): Promise<DdcConfig> {
        return this.apiPromise.then((api) => api.utils.getConfig());
    }

    insertElementToContent(elementToInsert: HTMLElement): Promise<void> {
        return new Promise<void>((resolve) => {
            this.apiPromise.then((api) => {
                api.insert('content', (contentElement: HTMLElement) => {
                    api.append(contentElement, elementToInsert);
                    resolve();
                });
            });
        });
    }
}
