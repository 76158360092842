/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * In this service we interface with the digital garage service and dis to determine
 * if we need to load the digital garage and interface with the CDN to actually load digital garage
 */
import { PageDGDataHub } from '@/models/PageDgDataHub.model';
import { loadRequiredScripts } from './scriptLoader';
import { Environments } from '@/enums/environments';
import LoaderLogger from './loaderLogger';

export const addInlineSavesFunctionsToDataHub = (): void => {
    const dataHub = window.DGDataHub as PageDGDataHub;

    dataHub.renderInlineSaves = (inlineSaveItems) => {
        if (!inlineSaveItems) {
            return;
        }

        if (!dataHub.inlineSaveItems) {
            dataHub.inlineSaveItems = {};
        }

        Object.assign(dataHub.inlineSaveItems, inlineSaveItems);
    };

    dataHub.deleteInlineSaveItem = (divId) => {
        if (dataHub.inlineSaveItems?.[divId]) {
            delete dataHub.inlineSaveItems?.[divId];
        }
    };

    dataHub.deleteInlineSaveItems = (divIds) => {
        divIds.forEach((divId) => divId && dataHub.deleteInlineSaveItem?.(divId));
    };
};

async function loadScripts() {
    const dataHub = window.DGDataHub as PageDGDataHub;
    if (dataHub.__internal__?.hasStartedLoadingDGScripts) {
        LoaderLogger.debug('Has already started loading DG scripts, so skipping this time');
        return;
    }
    dataHub.__internal__!.hasStartedLoadingDGScripts = true;
    await loadRequiredScripts();
    dataHub.__internal__!.hasLoadedDGScripts = true;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _setupDgDataHub = () => {
    const dataHub = window.DGDataHub as PageDGDataHub;

    dataHub.__internal__ ||= {};
    dataHub.__internal__.loaderArtifactVersion = '2.1.1-rc.1';

    dataHub.getDgid = () => {
        // placeholder function - do not remove
        LoaderLogger.warn('Called getDgid before DG is fully loaded');
        return undefined;
    };
    dataHub.showDealerWarning = (callbackContinue) => {
        callbackContinue();
        dataHub.continuePageLoad?.();
    };

    // Expose a function through the data hub that allows the page to inform us when we need to change dealers
    dataHub.changeActiveDealer = async (dealerCd) => {
        if (dataHub.dealerCd) {
            dataHub.dealerCd = dealerCd;
        } else {
            LoaderLogger.warn('dealerCd is not defined on the DGDataHub. Should not be calling changeActiveDealer.');
        }
    };

    // Expose a function whereby the page can know when DG has loaded and
    // if it is showing the dealer warning modal
    dataHub.getWarningState = () => {
        return { dgLoaded: false, modalShown: false };
    };

    addInlineSavesFunctionsToDataHub();

    try {
        const overrideEnvKey = '__dg_override_env__';
        const overrideEnv =
            window.localStorage.getItem(overrideEnvKey) || window.sessionStorage.getItem(overrideEnvKey);
        if (overrideEnv) {
            dataHub.DEPLOY_ENV = overrideEnv as Environments;
            LoaderLogger.warn('Using override env', overrideEnv);
        }
    } catch (error) {
        LoaderLogger.warn('Error setting override env', error);
    }
};

/* This function is fired on every page to gather info (especially item) context for the DG */
const setupDgDataHub = (tries = 0) => {
    return new Promise<void>((resolve, reject) => {
        // Every page MUST have a DGDataHub object; if it does not exist, an error will be thrown
        if (window.DGDataHub) {
            const dataHub = window.DGDataHub as PageDGDataHub;
            if (!dataHub.__internal__) {
                dataHub.__internal__ = {};
            }
            if (dataHub.__internal__.hasSetupDGDataHub) {
                LoaderLogger.debug('Has already started DG loader, so skipping this time');
                return;
            }
            LoaderLogger.debug('Setting up DGDataHub');
            dataHub.__internal__.hasSetupDGDataHub = true;
            _setupDgDataHub();
            resolve();
        } else {
            if (tries > 10) {
                reject(
                    new ReferenceError(
                        'We cannot detect any valid window.DGDataHub object ' +
                            'globally declared on this page. It should be of type object.'
                    )
                );
            } else {
                LoaderLogger.debug('Waiting for DGDataHub', { tries });
                setTimeout(() => setupDgDataHub(tries + 1), 50);
            }
        }
    });
};

export async function loadDg(): Promise<void> {
    await setupDgDataHub();
    await loadScripts();
}
